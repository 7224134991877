import { Checkbox, Col, Row } from 'antd';
import React from 'react';

const CheckPassword = ({ isVisible, selectedKey }) => {
  return (
    <Row align="middle" className="mt-5 ml-15" hidden={!isVisible}>
      <Col className="d-flex flex-vertical password-text" span={24}>
        <Checkbox.Group value={selectedKey} disabled>
          <Checkbox value="8_letter">
            Minimum 8 characters and maximum 16 character
          </Checkbox>
          <Checkbox disabled value="caps">
            At least 1 capital letter
          </Checkbox>
          <Checkbox disabled value="small">
            At least 1 small letter
          </Checkbox>
          <Checkbox disabled value="number">
            At least 1 number
          </Checkbox>
          <Checkbox disabled value="special">
            At least 1 special character
          </Checkbox>
        </Checkbox.Group>
      </Col>
    </Row>
  );
};

export default CheckPassword;
