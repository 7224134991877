import { DatePicker } from 'antd';
import * as eva from 'eva-icons';
import moment from 'moment';
import React, { useEffect } from 'react';
import { defaultDateFormat } from '../common/constants';

const { RangePicker } = DatePicker;
const CustomRangePicker = ({ setDateSelected, className }) => {
  const disabledDate = (current) => {
    // Can not select future date
    return current && current > moment().endOf('day');
  };
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RangePicker
      className={`custom-date-picker ${className}`}
      disabledDate={disabledDate}
      separator="-"
      popupStyle={{ top: 30 }}
      suffixIcon={<i data-eva="calendar-outline" />}
      format={defaultDateFormat}
      dropdownClassName="custom-date-picker-panel"
      placeholder={['Start Date', 'End Date']}
      renderExtraFooter={() => (
        <div className="text-danger">
          All data is based on Greenwich Mean Time (GMT)
        </div>
      )}
      getPopupContainer={(triggerNode) => triggerNode?.parentElement}
      ranges={{
        Yesterday: [moment().subtract(1, 'days'), moment()],
        'Last 8 Days': [moment().subtract(7, 'days'), moment()],
        'Last 31 Days': [moment().subtract(30, 'days'), moment()],
        'Last 3 Months': [moment().subtract(3, 'months'), moment()]
      }}
      onChange={(data, dates) => {
        if (data) {
          setDateSelected([
            moment(dates?.[0], defaultDateFormat),
            moment(dates?.[1], defaultDateFormat)
          ]);
        } else {
          setDateSelected('');
        }
      }}
    />
  );
};

export default CustomRangePicker;
