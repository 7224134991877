import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ORGANIZATIONS = gql`
  query tenantList($filter: TenantFilter!) {
    tenantList(filter: $filter) {
      total
      data {
        id
        organizationName
        adminEmail
        phoneNumber
        userLimit
        isDisabled
        timeZone
        ownerName
        userCount
        systemBranding
        planName
        addOn
        maxApprovalLevel
        inspectionAssetLimit
        instructionAssetLimit
        organizationType
        access
        featureConfig {
          stageMaxLimit
          issueTag
          issueRecommendation
        }
        subscription {
          validTill
          graceDays
          status
          createdAt
          updatedAt
          planObj {
            id
            name
            noOfDays
          }
        }
      }
    }
  }
`;

export const GET_PLANS = gql`
  query planList {
    planList {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_USERS = gql`
  query tenantUserList($filter: UserFilter!) {
    tenantUserList(filter: $filter) {
      total
      data {
        id
        name
        roles
        email
        isDisabled
        tenantUser {
          isActive
        }
      }
    }
  }
`;
