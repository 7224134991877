import { Empty, Spin } from 'antd';
import React from 'react';
import { NUMBER_OF_SKELETON_CARDS, SCROLL_PERCENT } from '../common/constants';
import CommonCard from './CommonCard';

const InfiniteScrollHandler = (props) => {
  const {
    children,
    wrapperClassName,
    loading,
    refetchData,
    setScrollFlag,
    hasMore,
    scrollFlag,
    dataLength = 0,
    skeletonRows = 5
  } = props;
  const handleScroll = (e) => {
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      (scrollTop + offsetHeight) / scrollHeight >= SCROLL_PERCENT;
    if (scrolledToBottom && hasMore && !loading && !scrollFlag) {
      setScrollFlag(true);
      refetchData();
    }
  };
  return (
    <div
      className={`${
        wrapperClassName || 'common-scroll-wrapper'
      } position-relative`}
      onScroll={handleScroll}
    >
      <div className="width-percent-100 infinite-data-container">
        {dataLength > 0 ? children : !loading && <Empty />}
      </div>
      {loading &&
        (dataLength ? (
          <Spin spinning className="infinite-loader" />
        ) : (
          <>
            {[...Array(NUMBER_OF_SKELETON_CARDS)].map((record, index) => (
              <CommonCard
                loading
                // eslint-disable-next-line react/no-array-index-key
                key={`card-${index}`}
                className="skeleton-card"
                skeletonRows={skeletonRows}
              />
            ))}
          </>
        ))}
    </div>
  );
};
export default InfiniteScrollHandler;
